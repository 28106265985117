// Custom Bootstrap variable overrides
$primary: #0A1647;
$secondary: #DDE0FF;

// Import Bootstrap SCSS
@import 'node_modules/bootstrap/scss/bootstrap';

$light-bg: #E9EDF8;
$text-color: #202020;
$border-radius: 8px;
$white: #fff;

.btn {
  border-radius: $border-radius;
  border: transparent;
  font-weight: 700;
}

// Custom button hover and focus states
.btn-primary {
  &:hover,
  &:focus {
    background-color: $light-bg;
    color: $text-color;

    svg {
      path {
        fill: $text-color;
        stroke: $text-color;
      }
    }
  }

  &:disabled,
  &.disabled {
    background-color: lighten($primary, 10%);
    border-color: lighten($primary, 15%);

    &.revert-disabled-styles {
      background-color: $primary;
      opacity: 1;
    }
  }
}

.btn-primary-opposite {
  background-color: $light-bg;
  color: $text-color;

  &:hover,
  &:focus {
    background-color: $primary;
    color: $light-bg;

    svg {
      path {
        fill: $light-bg;
        stroke: $light-bg;
      }
    }
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
    border-color: lighten($light-bg, 15%);
    cursor: not-allowed;
  }
}

// btn-secondary
.btn-secondary {
  &:hover,
  &:focus {
    background-color: darken($secondary, 10%);
    border-color: darken($secondary, 15%);
  }

  &:disabled,
  &.disabled {
    background-color: lighten($secondary, 10%);
    border-color: lighten($secondary, 15%);
  }
}

// btn-inactive
.btn-inactive {
  &:hover,
  &:focus {
    background-color: #E9E9E9;
    color: #202020;

    svg {
      path {
        fill: $text-color;
        stroke: $text-color;
      }
    }
  }

  &:disabled,
  &.disabled {
    background-color: lighten(#E9E9E9, 10%);
    border-color: lighten(#E9E9E9, 15%);

    &.revert-disabled-styles {
      background-color: #E9E9E9;
      color: #202020;
      opacity: 1;
    }
  }
}

// btn-menu
.btn-menu {
  background-color: transparent;
  color: $text-color;
  font-weight: 400;

  svg {
    width: 24px;

    path {
      stroke: #000;
    }
  }

  &.active,
  &:hover,
  &:focus {
    background-color: $primary;
    color: $white;
    font-weight: 700;

    svg {
      path {
        stroke: $white;
      }
    }
  }

  &:disabled,
  &.disabled {
    background-color: lighten($primary, 10%);
    border-color: lighten($primary, 15%);
  }
}

// btn-select
.btn-select {
  background-color: #fff;
  border-radius: 8px;
  color: $text-color;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 24px;

    path {
      stroke: #000;
    }
  }

  &.active,
  &:hover,
  &:focus {
    background-color: darken(#fff, 15%);
    color: $white;
    font-weight: 700;

    svg {
      path {
        stroke: $white;
      }
    }
  }

  &:disabled,
  &.disabled {
    background-color: lighten($primary, 10%);
    border-color: lighten($primary, 15%);
  }
}

// Statuses

.status {
  border: 5px solid #fff;

  &--active, &--done {
    background-color: #DBFFAC;
    color: #0A1647;
    font-size: 14px;

    &:disabled {
      background-color: #DBFFAC;
      color: #0A1647;
    }
  }

  &--open {
    background-color: #0A1647;
    color: #fff;
    font-size: 14px;
  }

  &--inactive {
    background-color: #DDE0FF;
    font-size: 14px;
  }

  &--backlog {
    background-color: #E9E9E9;
    font-size: 14px;
  }

  &--in-progress {
    background-color: #DDE0FF;
    font-size: 14px;
  }

  &--blocked, &--disabled {
    background-color: #FFDDDD;
    font-size: 14px;
  }
}

.Toastify {
  &__progress-bar {
    &--success {
      background: $primary;
    }
  }

  &__toast {
    &--success {
      svg {
        path {
          fill: $primary;
        }
      }
    }
  }
}
