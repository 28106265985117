.table-badge {
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #202020;
  display: flex;
  align-items: center;
  justify-content: center;

  &--small {
    max-width: 44px;
    height: 34px;
    margin: 0;
    padding: 0;
  }
}
