.empty-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 15px;
    row-gap: 15px;

    img {
      border-radius: 50%;
    }
  }
}
