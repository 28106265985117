.detail-title {
  margin-bottom: 24px;
  
  svg {
    width: 24px;
    height: 24px;

    path {
      stroke: #202020;
    }
  }
}
