@import "./../../../styles/_variables.scss";

.gbi-table {
  margin-bottom: 1rem;

  &.sidebar {
    margin-bottom: 1rem;

    &.full-size {
      .table-wrapper {
        max-height: 100%;
      }
    }

    .table-header {
      margin-bottom: 0.5rem !important;
    }

    .table-wrapper {
      max-height: 20vh;
      overflow: auto;

      .table-content {
        margin-bottom: 0;

        thead {
          tr {
            position: sticky;
            top: 0;
          }
        }

        th, td {
          padding: 10px 15px
        }
      }
    }
  }

  .table-wrapper {
    .table {
      table-layout: fixed;

      thead {
        tr {
          th {
            border-color: #EBEDFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            padding: 20px 33px;
            overflow: hidden;

            &:first-child {
              border-top-left-radius: 12px;
            }

            &:last-child {
              border-top-right-radius: 12px;
            }

            button {
              font-weight: 700;
              text-align: left;
              width: 100%;
              border: none;
              background: transparent;

              svg {
                margin-left: 0.5rem;
              }
            }
          }
        }
      }

      tbody {
        tr {

          &.is-interactive {
            &:hover {
              background-color: #0A1647;

              td {
                transition: all 250ms ease-in-out;
                background-color: #0A1647;
                color: #fff;

                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }
            }
          }

          td {
            border-color: #EBEDFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            padding: 13px 33px;
            vertical-align: middle;
            overflow: hidden;

            &.cursor-pointer {
              cursor: pointer;
            }
          }

          &:last-child {
            td {
              &:first-child {
                border-bottom-left-radius: 12px;
              }

              &:last-child {
                border-bottom-right-radius: 12px;
              }
            }
          }
        }
      }
    }
  }

  .table-footer {
    display: flex;
    justify-content: flex-end;

    button {
      height: 48px;
    }
  }
}


// todo find better place for that
// tableActions.tsx
.table-actions {
  display: flex;
  align-items: center;
  column-gap: 16px;

  a, svg {
    cursor: pointer;
  }
}

// TableHeaderActions.tsx
.table-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;

  .search-and-filters {
    .btn,
    input {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      min-width: 240px;
    }

    .input-group {
      max-height: 48px;

      input {
        border: none;
        max-width: 400px;
        padding: 13px 24px;
      }

      .input-group-text {
        position: absolute;
        right: 0;
        top: 0;
        height: 44px;
        margin-top: 2px;
        margin-right: 2px;
      }
    }


    span {
      border: none;
      background-color: #fff;

      &.active {
        color: #000;
        background-color: #fff;
        font-weight: bold;
      }
    }

    .dropdown {
      .btn {
        padding: 13px 24px !important;

        &:after {
          display: none;
        }

        &:before {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-square" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/></svg>');
          position: absolute;
          right: 0;
          top: 2px;
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.is-selected {
        .btn {
          font-weight: bold;

          &:after, &:before {
            display: none;
          }
        }
      }
    }

    .reset-filter {
      position: absolute;
      top: 0;
      right: 0;
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      background-color: transparent;
      cursor: pointer;
      justify-content: center;
      padding: 0;
    }

    .reset-all-filters, .extra-button {
      max-width: 100px;
      min-width: auto;
      height: 48px;
    }
  }

  .create-eye-filter {
    height: 48px;

    .create-new-button {
      button {
        height: 100%;
      }
    }
  }


}

// todo move disabled state into separate components
.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  row-gap: 1rem;

  &.disabled {

    color: $disabled-text-color;
    cursor: not-allowed;

    .gbi-table {
      &.sidebar {
        .table-wrapper {
          overflow: hidden;

          table {
            thead, tbody {
              th, tr {
                color: $disabled-text-color;

                td {
                  color: $disabled-text-color;

                  .table-badge {
                    filter: opacity(0.5);
                  }
                }

              }
            }
          }
        }
      }
    }

    h4 {
      color: $disabled-text-color;
    }
  }
}

.guidelines-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
