.pagination-wrapper {
  nav {
    ul {
      display: flex;
      justify-content: center;
      column-gap: 0.5rem;
      row-gap: 0.5rem;
      margin-bottom: 1rem;
    }
  }
}
