.container {
  background-color: #fff;
  border-radius: 12px;


  .dropdown {
    background-color: #EBEDFF;
    border-radius: 12px;

  }
  #dropdownMenuButton {
    background-color: #EBEDFF;
  }
}
