.login-page {
  > .container-fluid {
    height: 100vh;

    > .row {
      height: 100vh;

      .login-form {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
      }

      .background-image {
        background-color: #EEEDF1;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        svg {
          max-width: 400px;
        }
      }
    }
  }
}
