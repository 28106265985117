.checkbox-field {
  .checkbox-elements {
    display: flex;
    row-gap: 1rem;
    column-gap: 2rem;

    .checkbox-element {
      display: flex;
      column-gap: 0.5rem;
    }
  }

}
