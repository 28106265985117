.verification-code, .forgot-password, .new-password {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    min-width: 400px;
  }
}
