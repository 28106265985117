.sidebar-menu {
  height: 100dvh;
  position: sticky;
  top: 0;

  .header-part {
    .sidebar-header {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }


  .sidebar-menu-inside {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 550px;
  }

  .footer-part {
    p {
      font-size: 16px;
      margin: 0;
      color: #1A1B1F;

      &.email {
        font-size: 14px;
        color: #47464F;
      }
    }

    svg {
      cursor: pointer;
    }
  }
}
