body {
  background-color: #E9EDF8;
}

.detail-wrapper {
  .detail {
    min-height: calc(100dvh - 2rem);
    background-color: transparent;
  }
}

.content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.with-header {
    padding-top: 120px;
    position: relative;
  }
}
