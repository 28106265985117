@import "_variables.scss";

// Set base body styles
body {
  font-family: $font-stack-secondary;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $primary-color;
}

// Headings
h1, h2, h3, h4, h5, h6 {
  font-family: $font-stack;
  font-weight: $heading-font-weight;
  line-height: 100%;
}

h1 {
  font-size: 4rem; // 64px
}


h2 {
  font-size: 1.75rem; // 28px
}


h3 {
  font-size: 1.5rem; // 24px
  font-style: normal;
  font-weight: 400;
  line-height: $line-height-lg;
}


h4 {
  font-size: $font-size-lg; // 20px
  font-weight: 400;
}


h5 {
  font-size: $font-size-base; // 16px
}


h6 {
  font-size: $font-size-sm; // 14px
}


// Paragraph
p {
  font-family: $font-stack;
  margin-bottom: $line-height-base;
  font-size: $font-size-sm;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

// Labels
label {
  font-family: $font-stack;
  font-size: $font-size-sm;
  font-style: normal;
  font-weight: 400;
}

// Links
a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

// Utilities
.text-large {
  font-size: $font-size-lg;
}

.text-small {
  font-size: $font-size-sm;
}

.text-muted {
  color: $secondary-color;
}
