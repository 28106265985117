@import "../../styles/variables";

.jurisdiction-page {

}

.ahj-create-page {

}

.jurisdiction-type-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  background-color: #fff;
  padding: 24px 32px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: -12px;

  button {
    background-color: #EEF0FF;
    border: none;
    border-radius: 8px;
    width: 100%;
    text-align: left;
    padding: 13px 13px 13px 38px;
    position: relative;

    &:hover {
      background-color: #0A1647;
      color: #fff;

      &:before {
        border-color: #fff;
      }
    }

    &:before, &:after {
      content: '';
      position: absolute;
    }

    &:before {
      left: 13px;
      top: 13px;
      width: 20px;
      height: 20px;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid #202020;
    }

    &.active {
      background-color: #0A1647;
      color: #fff;

      &:before {
        border-color: #fff;
      }

      &:after {
        left: 18px;
        top: 18px;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
      }
    }

    font-family: $font-stack-secondary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px
  }
}
