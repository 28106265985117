.guidelines-details {
  .guideline-type {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    .single-guideline {
      display: flex;
      border-top: 1px solid #EBEDFF;
      border-bottom: 1px solid #EBEDFF;
      padding: 10px 0;
      margin-top: -1px;

      &.header {
        .type, .note {
          font-weight: bold;
        }
      }

      .type {
        width: 100px;
      }

      .note {
        width: 100%;
      }
    }
  }
}
