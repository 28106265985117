.filter-columns {
  border-radius: 8px;
  width: 48px;
  background-color: #fff;
  cursor: pointer;

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .dropdown-menu {
    li {
      label {
        width: 100%;
        position: relative;
        padding: 2px 5px 2px 5px;

        input {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }

      &.checked {
        label {
          font-weight: bold;
        }
      }

      &:not(.checked) {
        label {
          &:after {
            display: inline-block;
            content: "\00d7";
            position: absolute;
            right: 12px;
            z-index: -1;
          }
        }
      }
    }
  }
}
