.comments-section {

  .title-add-new-comment {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .all-comments {
    .single-comment {
      border-radius: 8px;
      padding: 1rem;
      background-color: #E9EDF8;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      .author-date {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .author {
          margin-bottom: 0;
          font-weight: bold;
        }

        .date {
          font-size: 0.75rem;
          color: #A0A0A0;
          margin-bottom: 0;
        }
      }

      .comment {
        margin-bottom: 0;
      }
    }
  }

  .add-new-comment {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    textarea, textarea:focus {
      border-width: 1px;
      border-color: #DDE0FF;
      border-style: solid;
      border-radius: 8px;
      padding: 1rem;
    }

    .submit-wrapper {
      display: flex;
      justify-content: flex-end;
      column-gap: 1rem;
      align-items: center;

      button {
        max-width: fit-content;
      }
    }
  }
}
