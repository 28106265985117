.gbi-tabs {
  .nav-tabs {
    border: none;
    background-color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-bottom: -8px;
    padding-bottom: 8px;

    .nav-item {
      button {
        border-color: transparent;
        border-width: 2px;
        color: #202020;

        &.active, &:hover {
          border-bottom-color: #202020;
          font-weight: bold;
        }
      }
    }
  }
}
