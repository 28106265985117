.navigation-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 32px;

  position: absolute;
  top: 30px;
  width: 100%;

  h4 {
    margin: 0;
  }

  .left {
    display: flex;
    align-items: center;

    .back {
      margin-right: 16px;

      &:hover {
        cursor: pointer;
        transition: 350ms all;

        rect {
          transition: 350ms all;
          fill: #0A1647;
        }

        path {
          transition: 350ms all;
          stroke: #fff;
        }
      }
    }
  }

  .right {
    display: flex;
    column-gap: 12px;

    button {
      height: 48px;
    }

    .cancel, .delete, .edit {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;

      background-color: #fff;
      height: 48px;
      width: 48px;
      border-radius: 8px;

      &:hover {
        background-color: #0A1647;
        cursor: pointer;
        transition: 350ms all;

        rect {
          transition: 350ms all;
          fill: #0A1647;
        }

        path {
          transition: 350ms all;
          stroke: #fff;
        }
      }
    }

    .delete {
      background-color: #FDD;
    }
  }
}
