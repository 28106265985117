@import "../../../styles/variables";

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.files-upload {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .files-list {
    display: flex;
    row-gap: 8px;
    flex-direction: column;

    .title {
      font-family: $font-stack-secondary;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 157.143% */
    }

    .file-single {
      height: 56px;
      display: flex;
      align-content: center;
      align-items: center;
      background-color: #EBEDFF;
      border-radius: 12px;
      padding: 12px 16px;
      justify-content: space-between;

      .description-part, .actions-part {

      }

      .description-part {
        display: flex;
        align-items: center;
        column-gap: 16px;

        .name {
          font-family: $font-stack-secondary;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px; /* 157.143% */
        }

        .date-file-size {
          font-family: $font-stack-secondary;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px; /* 100% */
        }

      }

      .actions-part {
        display: flex;
        align-items: center;
        column-gap: 16px;

        svg {
          cursor: pointer;

          &:hover {
            path {
              transition: all 0.3s ease-in-out;
              stroke: green;
            }
          }

          &.delete {
            &:hover {
              path {
                transition: all 0.3s ease-in-out;
                stroke: #FF0000;
              }
            }
          }
        }
      }
    }
  }

  .file-upload-input {
    width: 100%;
    height: 56px;
    border: 1px solid #EBEDFF;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }

    .upload-button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;
      column-gap: 12px;

      svg {
        width: 24px;
        height: 24px;

        &.uploading {
          animation: spin 2s linear infinite;
        }
      }

      .upload-description {
        font-family: $font-stack-secondary;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;

        div {
          &:nth-child(2) {
            font-family: $font-stack-secondary;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
          }
        }
      }
    }
  }


  input {
    width: 100%;
    border: 1px solid #EBEDFF;
    background-color: transparent;
  }

  .file-status-toggle {
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
  }
}
