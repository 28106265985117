@import "src/styles/_variables.scss";

.details {
  background-color: #fff;
  padding: 24px 32px;
  border-radius: 12px;

  &:not(.create, .edit) {
    input, textarea {
      border: none;
      background-color: transparent;
      padding-left: 0;

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }

  .field-wrapper {
    margin-bottom: 1rem;

    .error-text {
      display: none;
      color: red;
      font-size: 1rem;
    }

    &.error-occurred {
      input, .file-upload-input, .dropdown {
        border: 1px solid red;
      }

      .error-text {
        display: block;
      }
    }

    .input-field, .address-field {
      p {
        margin: 0;

        &.fw-bold {
          margin-bottom: 1rem;
        }
      }
    }

    strong {
      margin-bottom: 1rem;
      display: inline-block;
      font-size: $font-size-sm;
    }

    .input-field {
      .featured {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
        margin-bottom: 2rem;
      }

      input[type="datetime-local"] {
        max-width: 180px;
      }
    }

    &:last-child {
      margin-bottom: 0;

      p {
        margin-bottom: 0 !important;
      }
    }
  }

  .save-button {
    button {
      height: 48px;
    }
  }
}
