@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

// Define typography variables
$font-stack: 'Nunito', Helvetica, Arial, sans-serif;
$font-stack-secondary: 'Nunito', Helvetica, Arial, sans-serif;

$font-size-root: 16px; // Base font size
$font-size-base: 1rem; // 16px
$font-size-lg: 1.25rem; // 20px
$font-size-sm: 0.875rem; // 14px
$font-size-xs: 0.75rem; // 12px

$line-height-base: 1.5rem; // 24px
$line-height-compressed: 1.2rem; // 19.2px
$line-height-expanded: 1.75rem; // 28px
$line-height-lg: 2rem; // 32px

$heading-font-weight: 700;
$body-font-weight: 400;

$primary-color: #333; // Primary text color
$secondary-color: #555; // Secondary text color
$link-color: #0275d8; // Default link color
$link-hover-color: darken($link-color, 15%); // Link color on hover
$disabled-text-color: gray;
