.companies-detail {

  .col-lg-6 {
    position: relative;
  }

  .company-logo {
    position: absolute;
    top: 10px;
    right: 22px;
    width: 150px;
    height: 150px;
    border-radius: 12px;
  }
}

.companies-create-page {

}
